import React, {useEffect} from "react";
import SiteWrapper from "../SiteWrapper";
import {Alert, Card, Grid, Icon, Page, Form, Button, Table, Stamp, Text, Container,} from "tabler-react";

import {Field, FieldArray, FieldProps, Formik, getIn, ErrorMessage} from "formik";
import {Form as FormikForm} from "formik";
import * as yup from "yup";
import {useApp} from "../AppProvider";
import {useApiClient} from "../api";
import {store} from "react-notifications-component";
import {ToolsCard} from "../components/ToolsCard"

function SettingsPage() {
  const ApiClient = useApiClient();
  const {
    settingsInfo,
    setSettingsInfo,
    setIsSettingsInfoLoading,
    isSettingsInfoLoading,
    channelsInfo,
    setChannelsInfo,
    isChannelsInfoLoading,
    setIsChannelsInfoLoading,
    licenseInfo,
    isLicenseInfoLoading,
    setLicenseInfo,
    setIsLicenseInfoLoading,
    ringingsTestsList,
    setRingingsTestsList,
    setIsRingingsTestsListLoading,
    isRingingsTestsListLoading
  } = useApp();

  useEffect(() => {
    if (channelsInfo) return;
    if (isChannelsInfoLoading) return;
    setIsChannelsInfoLoading(true);
      ApiClient.controller
        .getChannels()
        .then((channels) => {
          setChannelsInfo(channels);
        })
        .catch((error) => {
          setIsChannelsInfoLoading(false);
        });
  });
  useEffect(() => {
    if (settingsInfo) return;
    if (isSettingsInfoLoading) return;
    setIsSettingsInfoLoading(true);
      ApiClient.controller
        .getSettings()
        .then(({settings}) => {
          setSettingsInfo({settings});
        })
        .catch((error) => {
          setIsSettingsInfoLoading(false);
        });
  });

  useEffect(() => {
    if (ringingsTestsList) return;
    if (isRingingsTestsListLoading) return;;
      setIsRingingsTestsListLoading(true);
      ApiClient.ringings
        .getTest()
        .then((tests) => {
          setRingingsTestsList(tests.sort(function (a, b) {
            return parseInt(a.id) - parseInt(b.id)
          }));
        })
        .catch((error) => {
          setIsRingingsTestsListLoading(false);
        });
  });
    useEffect(() => {
      if (licenseInfo) return;
      if (isLicenseInfoLoading) return;
      setIsLicenseInfoLoading(true);
      ApiClient.controller
        .getLicense()
        .then((license) => {
          setLicenseInfo(license);
        })
        .catch((error) => {
          setIsLicenseInfoLoading(false);
        });
  });

  function handleSaveChannels(data) {
    ApiClient.controller
      .updateChannels(data)
      .then((request) => {
        setChannelsInfo(data);
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        // console.log(request);

      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        // console.log(error);

      });}

  function handleSaveSettings(data) {
    ApiClient.controller
      .updateSettings(data)
      .then((request) => {
        setChannelsInfo(data);
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        // console.log(request);

      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        // console.log(error);

      });}

  function handleTestChannels(data) {
    ApiClient.playback
      .testChannel(data)
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 1000
          }
        });
        // console.log(request);

      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        // console.log(error);

      });}
  function handlePlayClick(play_id) {
    ApiClient.playback
      .play({
        id: play_id,
        type: "test",
      })
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        // console.log(request);
      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        // console.log(error);
      });
  }

  const channelsValidationSchema = yup.object().shape({
    channels: yup.array()
      .of(
        yup.object().shape({
          id: yup.number(),
          enabled: yup.boolean(),
          hit: yup.number().min(0).max(1000).required('Required'), // these constraints take precedence
          wait: yup.number().min(0).max(1000).required('Required'),
        })
      )
      .required('') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Minimum of 1 friends'),
  });

  const ChannelEnableSwitch = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Switch
                   {...field}
      />
    )
  }

  const ChimesEnableSwitch = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Switch
        label={"Куранты"}
        {...field}
      />
    )
  }

  const SchedulerEnableSwitch = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Switch
        label={"Планировщик"}
        {...field}
      />
    )
  }

  const HitInput = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Input
        type={"number"}
        invalid={errorMessage ? true : ""}
        placeholder={"0"}
        default={null}
        {...field}
      />
    )
  }

  const WaitInput = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Input
        type={"number"}
        invalid={errorMessage ? true : ""}
        placeholder={"0"}
        default={null}
        {...field}
      />
    )
  }

  return licenseInfo && channelsInfo &&  settingsInfo && <SiteWrapper>
    <Page.Content title="Настройки">
      <Grid.Row cards={true}>
        <Grid.Col sm={12} lg={8}>
          <Alert type="primary">
            <Icon className="icon" link={false} name="info"/> Для проверки настроек канала кликните на номер активного
            канала или нажмите на <Icon className="icon" name="bell"/> справа.
          </Alert>

          <Formik
            initialValues={ channelsInfo }
            onSubmit={values =>
              setTimeout(() => {
                handleSaveChannels(values);

                //alert(JSON.stringify(values, null, 2));
              }, 500)}
            validationSchema={channelsValidationSchema}
          >
            {({values, errors}) => (
              <FormikForm>
                <FieldArray name="channels">

                  {({push, remove}) => (
                    <Card>
                      <Card.Header><Icon className="icon pr-1" name="sliders"/><Card.Title>Настройки
                        каналов</Card.Title></Card.Header>
                      <Card.Body className="p-3">
                        <Table
                          className="table-vcenter table-sm">
                          <Table.Header>
                            <Table.Row className="text-center">
                              <Table.ColHeader className="w-1">№</Table.ColHeader>
                              <Table.ColHeader>Намотка</Table.ColHeader>
                              <Table.ColHeader>Удар</Table.ColHeader>
                              <Table.ColHeader className="w-1 text-center"><Icon name="sliders"/></Table.ColHeader>
                              <Table.ColHeader className="w-1 text-center"/>
                            </Table.Row>
                          </Table.Header>
                          <Table.Body>
                            {values.channels.map((channel, index) => {
                              return (
                                <>
                                  {index + 1 <= licenseInfo.channels ?
                                    <Table.Row key={channel.bell}>
                                      <Table.Col className="w-1">
                                        {channel.enable ?
                                          <span onClick={() => handleTestChannels({
                                            "channel": channel.bell + 1,
                                            "hit": channel.hit
                                          })}><Stamp size={"sm"} color="success">{channel.bell + 1} </Stamp></span> :
                                          <Stamp size={"sm"} color="disable">{channel.bell + 1}
                                          </Stamp>}
                                      </Table.Col>
                                      <Table.Col>
                                        <Field name={`channels[${index}].wait`}
                                               component={HitInput}
                                        />
                                      </Table.Col>
                                      <Table.Col>
                                        <Field name={`channels[${index}].hit`}
                                               component={WaitInput}
                                        />
                                      </Table.Col>
                                      <Table.Col className="w-1 text-center">

                                        <Field name={`channels[${index}].enable`}
                                               type="checkbox"
                                               component={ChannelEnableSwitch}
                                        />
                                      </Table.Col>
                                      <Table.Col>

                                        <Icon
                                          className="icon"
                                          link={true}
                                          name="bell"
                                          onClick={() => handleTestChannels({"channel" : channel.bell +1, "hit": channel.hit})}
                                        />
                                      </Table.Col>
                                    </Table.Row>
                                    : ""}
                                </>
                              );
                            })}

                          </Table.Body>
                        </Table>
                      </Card.Body>
                      <Card.Footer>
                        <Button block color="secondary" className="mt-3" icon="save" type="submit">
                          Сохранить настройки
                        </Button>
                      </Card.Footer>
                    </Card>
                  )}
                </FieldArray>
              </FormikForm>
            )}
          </Formik>
        </Grid.Col>
        <Grid.Col sm={12} lg={4}>
          <Card>
            <Card.Header>
              <span onDoubleClick={() =>  console.log("try change")}>
                <Icon className="icon pr-1" link={false} name="settings"/>
              </span>
              Настройки
              контроллера</Card.Header>
            <Card.Body>
              <Form.FieldSet>
                <Text>
                  Версия прошивки: <strong>{licenseInfo.version}</strong> build <strong>{licenseInfo.build}</strong>
                </Text>
                <Text>
                  Количество каналов: <strong>{licenseInfo.channels}</strong>
                </Text>
                <Text>
                  S/N: <strong>{licenseInfo.serial}</strong>
                </Text>
              </Form.FieldSet>
              <Formik
                initialValues={ settingsInfo }
                onSubmit={values =>
                  setTimeout(() => {
                    handleSaveSettings(values);
                    // console.log(values);
                  }, 500)}
             //   validationSchema={validationSchema}
              >
                {({values, errors, setFieldValue}) => (
                  <FormikForm>
                  <Form.Group>

                      <Field name={`settings.chimes`}
                           type="checkbox"
                             component={ChimesEnableSwitch}
                    />
                  </Form.Group>
                    <Form.Group>
                    <Field name={`settings.scheduler`}
                           type="checkbox"
                           component={SchedulerEnableSwitch}
                    />
                  </Form.Group>
                    <Button block color="secondary" className="mt-3" icon="save" type="submit">
                      Сохранить настройки
                    </Button>
                  </FormikForm>)}
                  </Formik>
            </Card.Body>
          </Card>
        <ToolsCard/>
        </Grid.Col>
      </Grid.Row>
      <Container className="p-0 pt-5">
        <Card>

          <Table
            cards={true}
            striped={true}
            responsive={true}
            className="table-vcenter table-sm">
            <Table.Header>
              <Table.Row>
                <Table.ColHeader>№</Table.ColHeader>
                <Table.ColHeader>Название</Table.ColHeader>
                <Table.ColHeader><Icon link={false} name="bell"/></Table.ColHeader>
                <Table.ColHeader>Длинна</Table.ColHeader>
                <Table.ColHeader/>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {
                ringingsTestsList.map(
                  tests =>
                    <Table.Row>
                      <Table.Col className="w-1">
                              <span onClick={() => handlePlayClick(tests.id)}>
                                <Stamp size={"sm"} color="success">{tests.id}</Stamp>
                              </span>
                      </Table.Col>
                      <Table.Col>{tests.name}</Table.Col>
                      <Table.Col className="w-1">{tests.channels}</Table.Col>
                      <Table.Col className="w-1">{tests.length}</Table.Col>
                      <Table.Col className="w-1">
                        <Icon
                          className="icon"
                          link={true}
                          name="play"
                          onClick={() => handlePlayClick(tests.id)}
                        />
                      </Table.Col>
                    </Table.Row>)
              }
            </Table.Body>
          </Table>


        </Card>
      </Container>
    </Page.Content>
  </SiteWrapper>;

}

export default SettingsPage;
