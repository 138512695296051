import * as React from "react";

import SiteWrapper from "../SiteWrapper";
import {Page, Icon, Grid, Card, Alert,} from "tabler-react";

function JobsPage() {

    return (
        <SiteWrapper>
            <Page.Content title="Наши работы">
                <Grid.Row cards={true}>
                    <Grid.Col>
                        <Alert className="alert-info"><Icon className="icon" link={false} name="info"/>
                            Данная страница находится в разработке, приносим свои извенения за временные неудобства.
                        </Alert>
                        <Card className="p-1">
                            <Grid.Row>
                                <Grid.Col sm={4} lg={4}>
                                    <img src="/static/jobs/turunovo/icon.jpg" alt=""/>
                                </Grid.Col>
                                <Grid.Col sm={8} lg={8}>
                                    <h5 className="text-center pt-3">ц. Светителя Николая, пос.Туруново</h5>
                                    Чувашкская республика, поселок Туруново, храм Святителя Николая.<br/>
                                    В сентябре 2020 года, были завершены работы по обустройству рабочего места звонаря и установлена автоматизированная система управления колоколами. Вес самого большого колокола 100 пудов и отлит 1914 году на колокололитейном заводе братьев Приваловых.
                                </Grid.Col>
                            </Grid.Row>
                            {/*<Card.Footer><a href="#" >Подробнее</a></Card.Footer>*/}
                        </Card>

                        <Card className="text-right p-1">
                            <Grid.Row>
                                <Grid.Col sm={8} lg={8}>
                                    <h5 className="text-center pt-3">ц. Сергия Радонежского, пос.Дивноморское </h5>
                                    Геленджик, п.Дивноморское, церковь Сергия Радонежского<br/>
                                    В мае 2022 года выполнены работы: добавлены два маленьких колокола, обновлена ручная система управления колоколами и установлен «электронный звонарь».
                                </Grid.Col>
                                <Grid.Col sm={4} lg={4} className="">
                                    <img src="/static/jobs/2/icon.jpg" alt=""/>
                                </Grid.Col>
                            </Grid.Row>
                        </Card>

                        <Card className="p-1">
                            <Grid.Row>
                                <Grid.Col sm={4} lg={4}>
                                    <img src="/static/jobs/3/icon.jpg" alt=""/>
                                </Grid.Col>
                                <Grid.Col sm={8} lg={8}>
                                    <h5 className="text-center pt-3">ц. Серафима Саровского, г.Минскк</h5>
                                    Республика Беларусь, город Минск, Церковь Серафима Саровского.<br/>
                                    В августе 2021 года, были доставлены новые колокола завода «Литэкс», для строящегося храма Серафима Саровского. Были размещены на временной конструкции, сделана ручная система управления и установлен «электронный звонарь». <br/>
                                    В Набор вошли 15 колоколов весом 4500, 2000, 850, 375, 220, 102, 73, 40, 30, 20, 14, 8, 6, 5, 4 кг.
                                </Grid.Col>
                            </Grid.Row>
                        </Card>

                        {/*<Card className="text-right p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. Оптинских старцев, г.Минск</h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={4} lg={4} className="">*/}
                        {/*            <img src="/static/jobs/4/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={4} lg={4}>*/}
                        {/*            <img src="/static/jobs/5/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. Богоявления, г.Бор</h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="text-right p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. Серафима Саровского, пос.Пятигорский</h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={4} lg={4} className="">*/}
                        {/*            <img src="/static/jobs/6/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={4} lg={4}>*/}
                        {/*            <img src="/static/jobs/7/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. трех Святителей, село Забалуйка</h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="text-right p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. Дмитрия Ростовского, г.Москва </h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={4} lg={4} className="">*/}
                        {/*            <img src="/static/jobs/8/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="p-1">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={4} lg={4}>*/}
                        {/*            <img src="/static/jobs/9/icon.jpg" alt=""/>*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            <h5 className="text-center pt-3">ц. Георгия Победоносца, пос.Нефедьево</h5>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                        {/*<Card className="text-right">*/}
                        {/*    <Grid.Row>*/}
                        {/*        <Grid.Col sm={8} lg={8}>*/}
                        {/*            Описание*/}
                        {/*        </Grid.Col>*/}
                        {/*        <Grid.Col sm={4} lg={4} className="">*/}
                        {/*            <img src="/static/jobs/turunovo/icon.jpg"/>*/}
                        {/*        </Grid.Col>*/}
                        {/*    </Grid.Row>*/}
                        {/*</Card>*/}

                    </Grid.Col>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default JobsPage;
