import * as React from 'react'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
//import { useApp } from "./AppProvider";

import {
  ChimesPage,
  HomePage,
  SchedulerPage,
  SettingsPage,
  JobsPage,
  VideosPage,
} from "./pages";

import "tabler-react/dist/Tabler.css";

type Props = {||};

function App(props: Props) {
  return (
    <React.StrictMode>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage}/>
            <Route exact path="/jobs" component={JobsPage}/>
            <Route exact path="/videos" component={VideosPage}/>
            <Route exact path="/chimes" component={ChimesPage}/>
            <Route exact path="/scheduler" component={SchedulerPage}/>
            <Route exact path="/settings" component={SettingsPage}/>
          </Switch>
        </Router>
    </React.StrictMode>
  );
}

export { App };
