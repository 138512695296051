import Controller from "./features/controller";
import Playback from "./features/playback";
import Ringings from "./features/ringings";

import axios from "axios";
import createHttpClient from "../http-client.js";
import { ApiClient } from "./ApiClient";

const { API_URL } = process.env;

export const createApiClient = () => {
  return new ApiClient({
    endpoint: API_URL,
    Controller,
    Playback,
    Ringings,
    HttpClient: createHttpClient({ client: axios }),
  });
};
