import React, {useEffect} from "react";

import SiteWrapper from "../SiteWrapper";
import {Icon, Card, Grid, Page, Button, Form, Alert, Stamp} from "tabler-react";
import {Field, FieldArray, FieldProps, Formik, getIn, ErrorMessage} from "formik";
import {Form as FormikForm} from "formik";
import * as yup from "yup";
import PropTypes from 'prop-types';
import {useApiClient} from "../api";
import {useApp} from "../AppProvider";
import {store} from "react-notifications-component";

function SchedulerPage() {
  const ApiClient = useApiClient();
  const {schedulerInfo, setSchedulerInfo, isSchedulerInfoLoading, setIsSchedulerInfoLoading} = useApp();

  useEffect(() => {
    if (schedulerInfo) return;
    //setIsChannelsInfoLoading(false);

    ApiClient.controller
      .getScheduler()
      .then((scheduler) => {
        setSchedulerInfo(scheduler);
        console.log(scheduler);
        setIsSchedulerInfoLoading(true);
      })
      .catch((error) => {
        console.log({error});
        setIsSchedulerInfoLoading(false);
      });
  });

  function handleSaveScheduler(data) {
    ApiClient.controller.updateScheduler(data)
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        setSchedulerInfo(data);
        console.log(request);

      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        console.log(error);

      });}
  const validationSchema = yup.object().shape({
    scheduler: yup.array()
      .of(
        yup.object().shape({
          hour: yup.number().min(0, 'min 0').max(23, 'max 23').required('Required'), // these constraints take precedence
          minute: yup.number().min(0, 'cmon').max(59).required('Required'), // these constraints take precedence
          day_of_week: yup.object().shape({
            monday: yup.boolean().required(),
            tuesday: yup.boolean().required(),
            wednesday: yup.boolean().required(),
            thursday: yup.boolean().required(),
            friday: yup.boolean().required(),
            saturday: yup.boolean().required(),
            sunday: yup.boolean().required(),
          }),
          tracks : yup.array().of(yup.number().default(null)),
        })
      )
      .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
      .min(1, 'Minimum of 1 scheduler').max(10),
  });

  const HourInput = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    console.log(field);
    return (
      <Form.Input
        invalid={errorMessage ? true : "" }
        type={"number"}
        min={0}
        max={23}
        placeholder='00'
        {...field}
      />
    )
  }
  HourInput.propTypes = {
    inputType: PropTypes.oneOfType(['number']).isRequired,
  }
  const MinuteInput = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Input
        invalid={errorMessage ? true : "" }
        type={"number"}
        min={0}
        max={59}
        placeholder={"00"}
        {...field}
      />
    )
  }

  const TracksInput = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Input
        type={"number"}
        invalid={errorMessage ? true : "" }
        placeholder={"0"}
        default={null}
        {...field}
      />
    )
  }
  TracksInput.propTypes = {
    inputType: PropTypes.oneOfType(['number']).isRequired,
  }

  const EnableSwitch = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Switch type={"checkbox"}
                   {...field}
      />
    )
  }


  const SelectGroupItem = ({field, form, form: {errors}, ...rest}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.SelectGroupItem
        {...rest}
        {...field}
      />
    )

  }

  return schedulerInfo && <SiteWrapper>
    <Formik
      initialValues={ schedulerInfo }
      onSubmit={values =>
        setTimeout(() => {
          handleSaveScheduler(values);
         // alert(JSON.stringify(values, null, 2));
        }, 500)}
      validationSchema={validationSchema}
    >
      {({values, errors}) => (
        <FormikForm>
          <FieldArray name="scheduler">
            {({push, remove}) => (
              <Page.Content title="Планировщик">
                <Grid.Row>
                  <Grid.Col>
                    <Alert type="primary">
                      <Icon className="icon" link={false} name="info"/> Максимальное количество
                      планировщиков составляет 10
                    </Alert>
                  </Grid.Col>
                </Grid.Row>
                <Grid.Row cards={true}>
                  {values.scheduler.map((scheduler, index) => {

                    return (
                      <Grid.Col lg={6} key={scheduler.id}>
                        <Card lg={6} statusColor="blue">
                          <Card.Header>
                            <Card.Options>

                              <Stamp size="sm" className="mr-1">
                                <Icon name="trash" onClick={() => remove(index)}/>

                              </Stamp>
                            </Card.Options>
                          </Card.Header>

                          <Card.Body className={"p-3"}>
                            <Form.Group label="Время начала">
                            <Form.InputGroup>
                              <Form.InputGroupPrepend>
                                <Form.InputGroupText>
                                  <Icon name={"clock"}/>
                                </Form.InputGroupText>
                              </Form.InputGroupPrepend>
                              <Field name={`scheduler[${index}].hour`}

                                     component={HourInput}
                              />
                              <Form.InputGroupText>
                                {":"}
                              </Form.InputGroupText>

                              <Field name={`scheduler[${index}].minute`}
                                     component={MinuteInput}
                              />
                              <Form.InputGroupAppend>
                                <Form.InputGroupText>
                                  <Field name={`scheduler[${index}].enabled`}
                                         type="checkbox"
                                         component={EnableSwitch}
                                  />
                                </Form.InputGroupText>
                              </Form.InputGroupAppend>
                            </Form.InputGroup>
                            </Form.Group>
                            <Form.Group label="Номера мелодий">
                              <Grid.Row>
                                <Grid.Col>
                                  <Field name={`scheduler[${index}].tracks[0]`}
                                         icon={"music"}
                                      component={TracksInput}
                                  />
                                </Grid.Col>
                                <Grid.Col>
                                  <Field name={`scheduler[${index}].tracks[1]`}
                                         icon={"music"}
                                    component={TracksInput}
                                  />
                                </Grid.Col>
                                <Grid.Col>
                                  <Field name={`scheduler[${index}].tracks[2]`}
                                         icon={"music"}
                                    component={TracksInput}
                                  />
                                </Grid.Col>
                              </Grid.Row>
                            </Form.Group>


                            <Form.Group label="Дни недели">
                              <Form.SelectGroup canSelectMultiple>
                                <Field
                                  name={`scheduler[${index}].day_of_week.monday`}
                                  label={"П"}
                                  component={SelectGroupItem}
                                />
                                <Field
                                  name={`scheduler[${index}].day_of_week.tuesday`}
                                  label={"В"}
                                  component={SelectGroupItem}
                                />
                                <Field
                                  name={`scheduler[${index}].day_of_week.wednesday`}
                                  label={"С"}
                                  component={SelectGroupItem}
                                />
                                <Field
                                  name={`scheduler[${index}].day_of_week.thursday`}
                                  label={"Ч"}
                                  component={SelectGroupItem}
                                />
                                <Field
                                  name={`scheduler[${index}].day_of_week.friday`}
                                  label={"П"}
                                  component={SelectGroupItem}
                                />
                                <Field
                                  name={`scheduler[${index}].day_of_week.saturday`}
                                  label={"С"}
                                  component={SelectGroupItem}
                                />
                                <Field name={`scheduler[${index}].day_of_week.sunday`}
                                       component={SelectGroupItem} label={"В"}
                                />
                              </Form.SelectGroup>
                            </Form.Group>

                          </Card.Body>

                        </Card>
                      </Grid.Col>
                    );
                  })}

                </Grid.Row>
                <Grid.Row>
                  <Grid.Col>
                    <Button block type="button" color="info" icon="plus"
                            onClick={() =>
                              push({
                                day_of_week: { "monday": false,
                                  "tuesday": false,
                                  "wednesday": false,
                                  "thursday": false,
                                  "friday": false,
                                  "saturday": false,
                                  "sunday": false},
                                hour: null,
                                minute: null,
                                tracks: [],
                                enabled: true
                              })
                            }
                    >
                      Добавить планировщик
                    </Button>
                    <Button block color="secondary" className="mt-3" icon="save" type="submit">
                      Сохранить настройки
                    </Button>
                  </Grid.Col>
                </Grid.Row>
              </Page.Content>

            )}
          </FieldArray>
          {/*
            <pre>{JSON.stringify(values, null, 2)}</pre>
            <pre>{JSON.stringify(errors, null, 2)}</pre>
          */}
        </FormikForm>
      )}
    </Formik>


  </SiteWrapper>
}

export default SchedulerPage;
