import Api from "../../../base/api";

const PLAYBACK = "./playback/";
const PLAY = PLAYBACK + "play/"
const STOP = PLAYBACK + "stop/"
const TEST = PLAYBACK + "test/"

export default class Playback extends Api {
  play = (data) => {
    return this.httpClient.post(PLAY, data);
  };

  stop = (data) => {
    return this.httpClient.post(STOP, data);
  };

  testChannel = (data) => {
    return this.httpClient.post(TEST, data);
  }

}
