import React from "react";
import ReactDOM from "react-dom";
import reportWebVitals from './reportWebVitals';

//import "./index.css";

//import { ApiClientProvider, ApiClientMock, ApiClient } from "./api";
//import { AppProvider } from "./AppProvider";
import { App }  from "./App";

// const { NODE_ENV } = process.env;
// console.log({ NODE_ENV });

const rootElement = document.getElementById("root");

if (rootElement) {
  ReactDOM.render(
    // <ApiClientProvider
    //   value={NODE_ENV === "production" ? ApiClient : ApiClientMock}
    // >
    //   <AppProvider>
        <App />,
    //   </AppProvider>
    // </ApiClientProvider>,
  rootElement);
} else {
  throw new Error("Could not find root element to mount to!");
}

reportWebVitals();
