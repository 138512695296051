import React from "react";
import PropTypes from "prop-types";

import {useState, useMemo, useEffect} from "react";
import {createContext, useContext} from "react";

function identity(n) {
  return n;
}

const AppContext = createContext({
  isAppLoading: false,
  setIsAppLoading: identity,

  ringingsBaseList: {},
  setRingingsBaseList: identity,
  isRingingsBaseListLoading: false,
  setIsRingingsBaseListLoading: identity,

  ringingsChimesList: {},
  setRingingsChimesList: identity,
  isRingingsChimesListLoading: false,
  setIsRingingsChimesListLoading: identity,

  ringingsTestsList: {},
  setRingingsTestsList: identity,
  isRingingsTestsListLoading: false,
  setIsRingingsTestsListLoading: identity,

  channelsInfoSettings: {},
  setChannelsInfoSettings: identity,
  setIsChannelsInfoLoading: identity,
  isChannelsInfoLoading: false,

  licenseInfo: {},
  setLicenseInfo: identity,
  setIsLicenseInfoLoading: identity,
  isLicenseInfoLoading: false,

  chimesInfo: {},
  setChimesInfo: identity,
  setIsChimesInfoLoading: identity,
  isChimesInfoLoading: false,

  settingsInfo: {},
  setSettingsInfo: identity,
  setIsSettingsInfoLoading: identity,
  isSettingsInfoLoading: false,
});

function useApp() {
  const App = useContext(AppContext);

  return App;
}

function AppProvider({children}) {

  const [channelsInfo, setChannelsInfo] = useState(null);
  const [isChannelsInfoLoading, setIsChannelsInfoLoading] = useState(false);

  const [settingsInfo, setSettingsInfo] = useState(null);
  const [isSettingsInfoLoading, setIsSettingsInfoLoading] = useState(false);

  const [licenseInfo, setLicenseInfo] = useState(null);
  const [isLicenseInfoLoading, setIsLicenseInfoLoading] = useState(false);

  const [chimesInfo, setChimesInfo] = useState(null);
  const [isChimesInfoLoading, setIsChimesInfoLoading] = useState(false);

  const [schedulerInfo, setSchedulerInfo] = useState(null);
  const [isSchedulerInfoLoading, setIsSchedulerInfoLoading] = useState(false);

  const [ringingsBaseList, setRingingsBaseList] = useState(null);
  const [isRingingsBaseListLoading, setIsRingingsBaseListLoading] = useState(false);

  const [ringingsChimesList, setRingingsChimesList] = useState(null);
  const [isRingingsChimesListLoading, setIsRingingsChimesListLoading] = useState(false);

  const [ringingsTestsList, setRingingsTestsList] = useState(null);
  const [isRingingsTestsListLoading, setIsRingingsTestsListLoading] = useState(false);

  const [isAppLoading, setIsAppLoading] = useState(false);


  useEffect(() => {
    if (!settingsInfo) {
      setIsAppLoading(true);
    } else {
      setIsAppLoading(false);
    }
  }, [])
  const store = useMemo(
    () => ({
      isAppLoading,
      setIsAppLoading,

      channelsInfo,
      setChannelsInfo,
      isChannelsInfoLoading,
      setIsChannelsInfoLoading,

      settingsInfo,
      setSettingsInfo,
      isSettingsInfoLoading,
      setIsSettingsInfoLoading,

      licenseInfo,
      setLicenseInfo,
      isLicenseInfoLoading,
      setIsLicenseInfoLoading,

      chimesInfo,
      setChimesInfo,
      isChimesInfoLoading,
      setIsChimesInfoLoading,

      ringingsBaseList,
      setRingingsBaseList,
      isRingingsBaseListLoading,
      setIsRingingsBaseListLoading,

      ringingsChimesList,
      setRingingsChimesList,
      isRingingsChimesListLoading,
      setIsRingingsChimesListLoading,

      ringingsTestsList,
      setRingingsTestsList,
      isRingingsTestsListLoading,
      setIsRingingsTestsListLoading,

      schedulerInfo, setSchedulerInfo,
      isSchedulerInfoLoading, setIsSchedulerInfoLoading,
    }),
    [
      channelsInfo,
      setChannelsInfo,
      isChannelsInfoLoading,
      setIsChannelsInfoLoading,

      settingsInfo,
      setSettingsInfo,
      isSettingsInfoLoading,
      setIsSettingsInfoLoading,

      licenseInfo,
      setLicenseInfo,
      isLicenseInfoLoading,
      setIsLicenseInfoLoading,

      chimesInfo,
      setChimesInfo,
      isChimesInfoLoading,
      setIsChimesInfoLoading,

      ringingsBaseList,
      setRingingsBaseList,
      isRingingsBaseListLoading,
      setIsRingingsBaseListLoading,

      ringingsChimesList,
      setRingingsChimesList,
      isRingingsChimesListLoading,
      setIsRingingsChimesListLoading,

      ringingsTestsList,
      setRingingsTestsList,
      isRingingsTestsListLoading,
      setIsRingingsTestsListLoading,

      schedulerInfo, setSchedulerInfo,
      isSchedulerInfoLoading, setIsSchedulerInfoLoading,
    ]
  );

  return <AppContext.Provider value={store}>{children}</AppContext.Provider>;
}

AppProvider.propTypes = {
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]).isRequired,
};

export {useApp, AppProvider};
