import {createApiClient} from "./ApiClient";
import {ApiClientMock} from "./api-client-mock";
import {createContext, useContext} from "react";

const { API_URL } = process.env;

const ApiClient = createApiClient();

if (API_URL) {
  ApiClient.setEndpoint(API_URL);
}

const ApiClientContext = createContext(ApiClient);

function useApiClient() {
  const ApiClient = useContext(ApiClientContext);

  return ApiClient;
}

const ApiClientProvider = ApiClientContext.Provider;

export { ApiClient, ApiClientMock, useApiClient, ApiClientProvider };
