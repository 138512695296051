import Api from "../../../base/api";

const RINGINGS = "./ringings/";

const BASE = RINGINGS + "base/"
const CHIMES = RINGINGS + "chimes/"
const TEST = RINGINGS + "test/"

export default class Ringings extends Api {
  getBase = () => {
    return this.httpClient.get(BASE);
  };

  getChimes = () => {
    return this.httpClient.get(CHIMES);
  };

  getTest = () => {
    return this.httpClient.get(TEST);
  };

}
