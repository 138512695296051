import mocks from "./mocks.json";

function mockResponse(mockData) {
    return (request) => {
        console.log("REQUEST: ", request ? {request} : "---");

        return new Promise((res) => {
            setTimeout(() => {
                    console.log("RESPONSE:", {mockData});
                res(mockData);
            }, 600);
        });
    };
}

const ApiClientMock = {
    controller: {
        getLicense: mockResponse(mocks.controller.license),
        getChannels: mockResponse(mocks.controller),
        updateChannels: mockResponse(mocks.messages.controller.channelsUpdate),
        update: mockResponse(mocks.messages.controller.update),
        getSettings: mockResponse(mocks.controller.settings),
        updateSettings: mockResponse(mocks.messages.controller.updateSettings),
        getChimes: mockResponse(mocks.controller.settings),
        getScheduler: mockResponse(mocks.controller.settings),
        updateScheduler: mockResponse(mocks.messages.controller.schedulerUpdate),
        updateChimes: mockResponse(mocks.messages.controller.chimesUpdate),
        updateTime: mockResponse(mocks.messages.controller.updateTime),
        restart: mockResponse(mocks.messages.controller.restart),
    },
    playback: {
        testChannel: mockResponse(mocks.playback.test),
        play: mockResponse(mocks.playback.play),
        stop: mockResponse(mocks.playback.stop)
    },
    ringings: {
        getBase: mockResponse(mocks.ringings.zvons),
        getChimes: mockResponse(mocks.ringings.kurants),
        getTest: mockResponse(mocks.ringings.tests)
    }
};

export {ApiClientMock};
