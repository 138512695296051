import * as React from "react";
import {NavLink, withRouter} from "react-router-dom";
import ReactNotifications from 'react-notifications-component';
import {Site, Nav, Grid, List, RouterContextProvider,} from "tabler-react";
import 'react-notifications-component/dist/theme.css';

import type {NotificationProps} from "tabler-react";

type Props = {|
  +children: React.Node,
|};

type State = {|
  notificationsObjects: Array<NotificationProps>,
|};

type subNavItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +LinkComponent?: React.ElementType,
  +useExact?: boolean,
|};

type navItem = {|
  +value: string,
  +to?: string,
  +icon?: string,
  +active?: boolean,
  +LinkComponent?: React.ElementType,
  +subItems?: Array<subNavItem>,
  +useExact?: boolean,
|};

const navBarItems: Array<navItem> = [
  {
    value: "Главная",
    to: "/",
    icon: "bell",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Наши работы",
    to: "/jobs",
    icon: "image",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  {
    value: "Видео",
    to: "/videos",
    icon: "video",
    LinkComponent: withRouter(NavLink),
    useExact: true,
  },
  // {
  //   value: "Цены",
  //   to: "/jobs",
  //   icon: "shopping-bag",
  //   LinkComponent: withRouter(NavLink),
  //   useExact: true,
  // },
  //   {
  //       value: "Контакты",
  //       to: "/jobs",
  //       icon: "users",
  //       LinkComponent: withRouter(NavLink),
  //       useExact: true,
  //   },
];

class SiteWrapper extends React.Component<Props, State> {
  state = {
    notificationsObjects: [],
  };

  // @ts-ignore
  render(): React.Node {
    return (
      <>
        <ReactNotifications/>
        <Site.Wrapper
          headerProps={{
            href: "/",
            alt: "zvonvkolokola.ru",
            imageURL: "./logo.svg",
            navItems: ([
                <Nav.Item>
                  <span>+7 (921) 650-45-35<br />zvonvkolokola@yandex.ru</span>
                </Nav.Item>
              ]
            ),
          }}
          navProps={{itemsObjects: navBarItems}}
          routerContextComponentType={withRouter(RouterContextProvider)}
          footerProps={{
            copyright: (
              <React.Fragment>
                Copyright © 2022 {" "}
                <a href="https://zvonvkolokola.ru">zvonvkolokola.ru</a>. {" "}
                All rights reserved.
              </React.Fragment>
            ),
            nav: (
              <React.Fragment>
                <Grid.Col auto={true}>
                  <List className="list-inline list-inline-dots mb-0">
                    <List.Item className="list-inline-item">
                      {
                        // <a href="./docs/index.html"><Icon name={"file-text"}> </Icon> Documentation</a>
                      }

                    </List.Item>
                  </List>
                </Grid.Col>
              </React.Fragment>
            ),
          }}
        >
          {this.props.children}
        </Site.Wrapper>
      </>
    );
  }
}

export default SiteWrapper;
