import * as React from "react";

import SiteWrapper from "../SiteWrapper";
import {Page, Grid, Card,} from "tabler-react";
import YoutubeEmbed from "../components/YoutubeEmbed";

function VideosPage() {

    return (
        <SiteWrapper>
            <Page.Content title="Видео">
                <Grid.Row cards={true}>
                    <Card className="text-center p-1 pt-2">
                        <YoutubeEmbed className="pt-1" embedId="oYnuF1vpaJg" />
                    </Card>
                    <Card className="text-center p-1 pt-2">
                        <YoutubeEmbed className="pt-1" embedId="B1eGygz2hOc" />
                    </Card>
                    <Card className="text-center p-1 pt-2">
                        <YoutubeEmbed className="pt-1" embedId="Zcwr7UwAXu8" />
                    </Card>
                    <Card className="text-center p-1 pt-2">
                        <YoutubeEmbed className="pt-1" embedId="UeLouKwBnXQ" />
                    </Card>
                    <Card className="text-center p-1 pt-2">
                        <YoutubeEmbed className="pt-1" embedId="uJzl-Wfh0rM" />
                    </Card>
                </Grid.Row>
            </Page.Content>
        </SiteWrapper>
    )
}

export default VideosPage;
