import { removeEmpty } from "./utils";

export default ({ client }) =>
  class HttpClient {
    constructor({ baseURL }) {
      Object.assign(this, { baseURL });
      let headers = {
        "Content-Type": "application/json",
      };

      this._client = client.create({
        baseURL,
        headers,
      });

      let multipartHeaders = {
        "Content-Type": "multipart/form-data",
      };

      this._multipartClient = client.create({
        baseURL,
        multipartHeaders,
      });
    }

    setBaseURL(baseURL) {
      if (baseURL) {
        this._client.defaults.baseURL = baseURL;
      }
    }

    post(url, data) {
      return this._client.post(url, removeEmpty(data)).then(({ data }) => data);
    }

    get(url) {
      return this._client.get(url).then(({ data }) => data);
    }

    multipartPatch(url, data) {
      return this._multipartClient
        .patch(url, removeEmpty(data))
        .then(( {data} ) => data);
    }

    patch(url, data) {
      return this._client
        .patch(url, removeEmpty(data))
        .then(({ data }) => data);
    }

    setHeaders(headers) {
      this._client.headers = headers;
    }

    delete(url) {
      return this._client.delete(url).then(({ data }) => data);
    }
  };
