import Api from "../../../base/api";

const CONTROLLER = "./controller/";
const LICENSE = CONTROLLER + "license/";
const CHANNELS = CONTROLLER + "channels/";
const TIME = CONTROLLER + "time/"
const UPDATE = CONTROLLER + "update/"
const RESTART = CONTROLLER + "restart/"
const RESET = CONTROLLER + "reset/"
const SCHEDULER = CONTROLLER + "scheduler/"
const CHIMES = CONTROLLER + "chimes/"
const SETTINGS = CONTROLLER + "settings/"

export default class Controller extends Api {
  getLicense = () => {
    return this.httpClient.get(LICENSE);
  };

  updateLicense = (data) => {
    return this.httpClient.post(LICENSE, data);
  };

  getChannels = () => {
    return this.httpClient.get(CHANNELS);
  };

  updateChannels = (data) => {
    return this.httpClient.post(CHANNELS, data);
  };

  getTime = () => {
    return this.httpClient.get(TIME);
  }

  updateTime = (data) => {
    return this.httpClient.post(TIME, data);
  }

  update = () => {
    return this.httpClient.post(UPDATE);
  };

  restart = () => {
    return this.httpClient.post(RESTART);
  };

  reset = (data) => {
    return this.httpClient.post(RESET, data);
  }

  getScheduler = () => {
    return this.httpClient.get(SCHEDULER);
  }

  updateScheduler = (data) => {
    return this.httpClient.post(SCHEDULER, data);
  }

  getChimes = () => {
    return this.httpClient.get(CHIMES);
  }

  updateChimes = (data) => {
    return this.httpClient.post(CHIMES, data);
  }

  getSettings = () => {
    return this.httpClient.get(SETTINGS);
  };

  updateSettings = (data) => {
    let querystring = require('querystring');
    return this.httpClient.multipartPatch(SETTINGS, querystring.stringify(data.settings));
  };
}

