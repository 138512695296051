import React, {useEffect} from "react";

import {
  Container,
  Grid,
  Card,
  Button,
  Form,
  Page,
  Icon, Table, Stamp, Alert,

} from "tabler-react";

import SiteWrapper from "../SiteWrapper";
import {useApiClient} from "../api";
import {useApp} from "../AppProvider";
import {Field, FieldProps, getIn, Formik} from "formik";
import {Form as FormikForm} from "formik";
import * as yup from "yup";
import {store} from "react-notifications-component";

function ChimesPage() {
  const ApiClient = useApiClient();
  const {
    chimesInfo,
    setChimesInfo,
    isChimesInfoLoading,
    setIsChimesInfoLoading,
    licenseInfo,
    isLicenseInfoLoading,
    setLicenseInfo,
    setIsLicenseInfoLoading,
    ringingsChimesList,
    setRingingsChimesList,
    setIsRingingsChimesListLoading,
    isRingingsChimesListLoading
  } = useApp();

  useEffect(() => {
    if (licenseInfo) return;
    if (isLicenseInfoLoading) return;
    setIsLicenseInfoLoading(true);
      ApiClient.controller
        .getLicense()
        .then((license) => {
          setLicenseInfo(license);
        })
        .catch((error) => {
          setIsLicenseInfoLoading(false);
        });

  });
    useEffect(() => {
    if (chimesInfo) return;
    if (isChimesInfoLoading) return;
    setIsChimesInfoLoading(true);
      ApiClient.controller
        .getChimes()
        .then((chimes) => {
          setChimesInfo(chimes);
        })
        .catch((error) => {
          console.log({error});
          setIsChimesInfoLoading(false);
        });
    });
      useEffect(() => {
    if (ringingsChimesList) return;
    if (isRingingsChimesListLoading) return;
        setIsRingingsChimesListLoading(true);
      ApiClient.ringings
        .getChimes()
        .then((kurants) => {
          setRingingsChimesList(kurants.sort(function (a, b) {
            return parseInt(a.id) - parseInt(b.id)
          }));
        })
        .catch((error) => {
          console.log({error});
          setIsRingingsChimesListLoading(false);
        });
  });

  function handlePlayClick(play_id) {
    ApiClient.playback
      .play({
        id: play_id,
        type: "chimes",
      })
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(request);
      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(error);
      });
  }

  function handleSaveChimes(data) {
    ApiClient.controller.updateChimes(data)
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        setChimesInfo(data);
        console.log(request);

      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 3000
          }
        });
        console.log(error);
      });
  }

  const validationSchema = yup.object().shape({
    chimes:
      yup.object().shape({
        bell: yup.number().min(1, 'min 1').max(32, 'max 8').required('Required'), // these constraints take precedence
        //  delay: yup.number().min(1000, 'cmon').max(10000).required('Required'), // these constraints take precedence
        hour_start: yup.number().min(0).max(23).required(),
        hour_end: yup.number().min(0).max(23).required(),
        days: yup.object().shape({
          monday: yup.boolean().required(),
          tuesday: yup.boolean().required(),
          wednesday: yup.boolean().required(),
          thursday: yup.boolean().required(),
          friday: yup.boolean().required(),
          saturday: yup.boolean().required(),
          sunday: yup.boolean().required(),
        }),
        tracks: yup.array().of(
          yup.object().shape({
            start: yup.number().min(0).max(59),
            enable: yup.boolean().required(),
            melody: yup.number().min(0).max(50),
          })),
      })

    //  .required('Must have friends') // these constraints are shown if and only if inner constraints are satisfied
    //  .min(1, 'Minimum of 3 friends').max(10),
  });

  const RatioItem = ({field, form, form: {errors}, ...rest}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Grid.Row alignItems="center">
        <span>{errorMessage}</span>
        <Grid.Col>
          <input
            className={"form-control custom-range"}
            type={"range"}
            max={23}
            min={0}
            step={1}
            {...field}
            {...rest}
          />
        </Grid.Col>
        <Grid.Col auto>
          <input
            type="number"
            className="form-control w-8"
            value={field.value}
            readOnly
          />
        </Grid.Col>
      </Grid.Row>
    )
  }

  const RatioBellItem = ({field, form, form: {errors}, ...rest}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    if (field.value > licenseInfo.channels) field.value = licenseInfo.channels;
    return (
      <Grid.Row alignItems="center">
        <span>{errorMessage}</span>
        <Grid.Col>
          <input
            className={"form-control custom-range"}
            type={"range"}
            max={licenseInfo.channels}
            min={1}
            step={1}
            {...field}
            {...rest}
          />
        </Grid.Col>
        <Grid.Col auto>
          <input
            type="number"
            className="form-control w-8"
            value={field.value}
            readOnly
          />
        </Grid.Col>
      </Grid.Row>)
  }

  const SelectGroupItem = ({field, form, form: {errors}, ...rest}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.SelectGroupItem
        {...rest}
        {...field}
      />
    )
  }
  const EnableSwitch = ({field, form: {errors}}: FieldProps) => {
    const errorMessage = getIn(errors, field.name);
    return (
      <Form.Switch type={"checkbox"}
                   {...field}
      />
    )
  }

  return chimesInfo && ringingsChimesList &&
    <SiteWrapper>

      <Page.Content title="Куранты" className="my-md-5">
        <div className="my-2 my-md-5">
          <Formik
            initialValues={chimesInfo}
            onSubmit={values =>
              setTimeout(() => {
                handleSaveChimes(values);
                console.log(values);
              }, 500)}
            validationSchema={validationSchema}
          >
            {({values, errors, setFieldValue}) => (
              <FormikForm>
                <Card statusColor="blue" >
                  <Card.Header>

                    <Card.Title> <Icon className="icon" link={false} name="settings"/> Параметров курантов</Card.Title>
                    <Card.Options>
                    </Card.Options>
                   </Card.Header>
                <Container className="p-0">
                  <Grid.Row>
                    <Grid.Col lg={6}>
                        <Card.Body className="p-3">
                          <Form.Group label="Начало">
                            <Field
                              name={`chimes.hour_start`}
                              component={RatioItem}
                            />
                          </Form.Group>
                          <Form.Group label="Окончание">
                            <Field
                              name={`chimes.hour_end`}
                              component={RatioItem}
                            />
                          </Form.Group>
                          <Form.Group label="Канал">
                            <Field
                              name={`chimes.bell`}
                              component={RatioBellItem}
                            />
                          </Form.Group>
                          <Form.Group label="Дни недели">
                            <Form.SelectGroup canSelectMultiple>
                              <Field
                                name={`chimes.days.monday`}
                                label={"П"}
                                component={SelectGroupItem}
                              />
                              <Field
                                name={`chimes.days.tuesday`}
                                label={"В"}
                                component={SelectGroupItem}
                              />
                              <Field
                                name={`chimes.days.wednesday`}
                                label={"С"}
                                component={SelectGroupItem}
                              />
                              <Field
                                name={`chimes.days.thursday`}
                                label={"Ч"}
                                component={SelectGroupItem}
                              />
                              <Field
                                name={`chimes.days.friday`}
                                label={"П"}
                                component={SelectGroupItem}
                              />
                              <Field
                                name={`chimes.days.saturday`}
                                label={"С"}
                                component={SelectGroupItem}
                              />
                              <Field name={`chimes.days.sunday`}
                                     component={SelectGroupItem} label={"В"}
                              />
                            </Form.SelectGroup>
                          </Form.Group>
                        </Card.Body>
                    </Grid.Col>
                    <Grid.Col lg={6}>
                        <Card.Body className="p-3">
                          <Form.Group label="Мелодия в 00 минут">
                            <Grid.Row gutters="sm">
                              <Grid.Col>
                                <Form.InputGroup>
                                  <Form.InputGroupPrepend>
                                    <Form.InputGroupText>
                                      <Icon name={"music"}/>
                                    </Form.InputGroupText>
                                  </Form.InputGroupPrepend>
                                  <Field as="select" name="chimes.tracks[0].melody" className="custom-select">
                                    {ringingsChimesList.map((obj) => {
                                      return <option value={obj.id}>{obj.name}</option>
                                    })}
                                  </Field>
                                  <Form.InputGroupAppend>
                                    <Form.InputGroupText>
                                      <Field name={`chimes.tracks[0].enable`}
                                             type="checkbox"
                                             component={EnableSwitch}
                                      />
                                    </Form.InputGroupText>
                                  </Form.InputGroupAppend>
                                </Form.InputGroup>
                              </Grid.Col>
                            </Grid.Row>
                          </Form.Group>
                          <Form.Group label="Мелодия в 15 минут">
                            <Grid.Row gutters="sm">
                              <Grid.Col>
                                <Form.InputGroup>
                                  <Form.InputGroupPrepend>
                                    <Form.InputGroupText>
                                      <Icon name={"music"}/>
                                    </Form.InputGroupText>
                                  </Form.InputGroupPrepend>
                                  <Field as="select" name="chimes.tracks[1].melody" className="custom-select">
                                    {ringingsChimesList.map((obj) => {
                                      return <option value={obj.id}>{obj.name}</option>
                                    })}
                                  </Field>
                                  <Form.InputGroupAppend>
                                    <Form.InputGroupText>
                                      <Field name={`chimes.tracks[1].enable`}
                                             type="checkbox"
                                             component={EnableSwitch}
                                      />
                                    </Form.InputGroupText>
                                  </Form.InputGroupAppend>
                                </Form.InputGroup>
                              </Grid.Col>
                            </Grid.Row>
                          </Form.Group>
                          <Form.Group label="Мелодия в 30 минут">
                            <Grid.Row gutters="sm">
                              <Grid.Col>
                                <Form.InputGroup>
                                  <Form.InputGroupPrepend>
                                    <Form.InputGroupText>
                                      <Icon name={"music"}/>
                                    </Form.InputGroupText>
                                  </Form.InputGroupPrepend>
                                  <Field as="select" name="chimes.tracks[2].melody" className="custom-select">
                                    {ringingsChimesList.map((obj) => {
                                      return <option value={obj.id}>{obj.name}</option>
                                    })}
                                  </Field>
                                  <Form.InputGroupAppend>
                                    <Form.InputGroupText>
                                      <Field name={`chimes.tracks[2].enable`}
                                             type="checkbox"
                                             component={EnableSwitch}
                                      />
                                    </Form.InputGroupText>
                                  </Form.InputGroupAppend>
                                </Form.InputGroup>
                              </Grid.Col>
                            </Grid.Row>
                          </Form.Group>
                          <Form.Group label="Мелодия в 45 минут">
                            <Grid.Row gutters="sm">
                              <Grid.Col>
                                <Form.InputGroup>
                                  <Form.InputGroupPrepend>
                                    <Form.InputGroupText>
                                      <Icon name={"music"}/>
                                    </Form.InputGroupText>
                                  </Form.InputGroupPrepend>
                                  <Field as="select" name="chimes.tracks[3].melody" className="custom-select">
                                    {ringingsChimesList.map((obj) => {
                                      return <option value={obj.id}>{obj.name}</option>
                                    })}
                                  </Field>
                                  <Form.InputGroupAppend>
                                    <Form.InputGroupText>
                                      <Field name={`chimes.tracks[3].enable`}
                                             type="checkbox"
                                             component={EnableSwitch}
                                      />
                                    </Form.InputGroupText>
                                  </Form.InputGroupAppend>
                                </Form.InputGroup>
                              </Grid.Col>
                            </Grid.Row>
                          </Form.Group>
                        </Card.Body>

                    </Grid.Col>
                  </Grid.Row>

                </Container>

                  <Card.Footer>
                    <Container className="p-0">
                      <Button block color="secondary" icon="save" type="submit">Сохранить настройки</Button>
                    </Container>
                  </Card.Footer>
                </Card>
              </FormikForm>
              )}
          </Formik>
          <Container className="p-0 pt-5">
            <Card>

              <Table
                cards={true}
                striped={true}
                responsive={true}
                className="table-vcenter table-sm">
                <Table.Header>
                  <Table.Row>
                    <Table.ColHeader>№</Table.ColHeader>
                    <Table.ColHeader>Название</Table.ColHeader>
                    <Table.ColHeader><Icon link={false} name="bell"/></Table.ColHeader>
                    <Table.ColHeader>Длинна</Table.ColHeader>
                    <Table.ColHeader/>
                  </Table.Row>
                </Table.Header>
                <Table.Body>
                  {
                    ringingsChimesList.map(
                      chimes =>
                        <Table.Row>
                          <Table.Col className="w-1">
                              <span onClick={() => handlePlayClick(chimes.id)}>
                                <Stamp size={"sm"} color="success">{chimes.id}</Stamp>
                              </span>
                          </Table.Col>
                          <Table.Col>{chimes.name}</Table.Col>
                          <Table.Col className="w-1">{chimes.channels}</Table.Col>
                          <Table.Col className="w-1">{chimes.length}</Table.Col>
                          <Table.Col className="w-1">
                            <Icon
                              className="icon"
                              link={true}
                              name="play"
                              onClick={() => handlePlayClick(chimes.id)}
                            />
                          </Table.Col>
                        </Table.Row>)
                  }
                </Table.Body>
              </Table>


            </Card>
          </Container>
        </div>
      </Page.Content>
    </SiteWrapper>

}

export default ChimesPage;
