import * as React from "react";

import SiteWrapper from "../SiteWrapper";
import {Page, Icon, Grid, Card,} from "tabler-react";

import YoutubeEmbed from "../components/YoutubeEmbed";

function HomePage() {

  return (
    <SiteWrapper>
      <Page.Content title="">
        <h3 className="text-center">
          Добро пожаловать в нашу мастерскую «Звонарское дело».
        </h3>
        <Card className="p-1">
          <img  src="/static/img/main.jpg" alt="sdas"/>
        </Card>
        <Grid.Row cards={true}>
          <Grid.Col sm={12} lg={12}>
            <Card className="p-5">
              <p>
                <Icon name="users"/> Наша команда состоит из инженеров-монтажников, программистов и звонарей.
              </p>
              <p>
                <Icon name="watch"/> Последние 17 лет мы занимаемся обустройством колоколен по всей России.
              </p>
              <p>
                <Icon name="award"/> За нашими плечами опыт подвески колоколов от самых маленьких
                    до многотонных благовестников.
              </p>
              <p>
                <Icon name="activity"/> Накопившийся опыт и профессиональные знания помогли нам
                создать современное  устройство управления колоколами  - электронный звонарь.
              </p>
              <p>
                <Icon name="bell"/> Электронный звонарь – это устройство, предназначенное для управления колоколами в автоматическом режиме и воспроизведения колокольных звонов при помощи электрических приводов. Так же может использоваться в качестве курантов (отбивать время каждый час с мелодиями и четвертями).
              </p>
            </Card>
            <Card className="text-center p-1">
              <YoutubeEmbed className="pt-1" embedId="oYnuF1vpaJg" />
            </Card>
            <h3>Наши работы</h3>
            <Grid.Row cards={true} >
              <Grid.Col sm={4} lg={4}>
              <Card>
                <Card.Title className="text-center pt-2 mb-4">ц. Светителя Николая, пос.Туруново </Card.Title>
                <img src="/static/jobs/turunovo/icon.jpg" alt=""/>
              </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Сергия Радонежского, пос.Дивноморское </Card.Title>
                  <img src="/static/jobs/2/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Серафима Саровского, г.Минскк </Card.Title>
                  <img src="/static/jobs/3/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row cards={true} >
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Оптинских старцев, г.Минск </Card.Title>
                  <img src="/static/jobs/4/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Богоявления, г.Бор </Card.Title>
                  <img src="/static/jobs/5/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Серафима Саровского, пос.Пятигорский </Card.Title>
                  <img src="/static/jobs/6/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
            </Grid.Row>
            <Grid.Row cards={true} >
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. трех Святителей, село Забалуйка </Card.Title>
                  <img src="/static/jobs/7/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Дмитрия Ростовского, г.Москва </Card.Title>
                  <img src="/static/jobs/8/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
              <Grid.Col sm={4} lg={4}>
                <Card>
                  <Card.Title className="text-center pt-2 mb-4">ц. Георгия Победоносца, пос.Нефедьево </Card.Title>
                  <img src="/static/jobs/9/icon.jpg" alt=""/>
                </Card>
              </Grid.Col>
            </Grid.Row>
          </Grid.Col>
        </Grid.Row>
      </Page.Content>
    </SiteWrapper>
)
}

export default HomePage;
