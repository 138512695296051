export class ApiClient {
  constructor({ endpoint = null, HttpClient, Controller, Playback, Ringings }) {
    this.endpoint = endpoint;

    this.httpClient = new HttpClient({
      baseURL: endpoint,
    });

    this.controller = new Controller({ httpClient: this.httpClient });
    this.playback = new Playback({ httpClient: this.httpClient })
    this.ringings = new Ringings({ httpClient: this.httpClient })
  }

  setEndpoint(endpoint) {
    this.httpClient.setBaseURL(endpoint);
  }
}
