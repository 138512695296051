import React from "react";

import {useApiClient} from "../api";
import {Card, Icon, StampCard} from "tabler-react";
import {store} from "react-notifications-component";

function ToolsCard() {
  const ApiClient = useApiClient();

  function handleUpdateClick() {
    ApiClient.controller.update()
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(request);
      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(error);
      });
  }
  function handleRestartClick() {
    ApiClient.controller.restart()
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(request);
      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(error);
      });
  }
  function handleTimeSyncClick() {
    let data = {"timestamp": Math.floor(Date.now()/1000)};
    ApiClient.controller.updateTime(data)
      .then((request) => {
        store.addNotification({
          title: "Success!",
          message: request.message,
          type: "success",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(request);
      })
      .catch((error) => {
        store.addNotification({
          title: "Error!",
          message: error.message,
          type: "warning",
          insert: "top",
          container: "top-right",
          animationIn: ["animate__animated", "animate__fadeIn"],
          animationOut: ["animate__animated", "animate__fadeOut"],
          dismiss: {
            duration: 5000
          }
        });
        console.log(error);
      });
  }
  return (
    <Card>
      <Card.Header> <Icon className="icon pr-1" link={false} name="activity"/> Инструменты</Card.Header>
      <Card.Body>
        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
        <span onClick={() => handleUpdateClick()}>
          <StampCard header="Обновление" footer="Текущая версия: 0.3.2" color="green" icon="refresh-ccw"/>
        </span>
        <span onClick={() => handleRestartClick()}>
        <StampCard header="Перезагрузка" icon="zap" color="gray"/>
        </span>
        <span onClick={() => handleTimeSyncClick()}>
        <StampCard header="Синхронизация" footer="Синхронизация времени" icon="clock" color="blue"/>
        </span>
      </Card.Body>
    </Card>);
}

export { ToolsCard };
